<template>
  <router-view />
</template>

<script>

export default {
  name: 'TestMembersCreationRoutes',
  mounted () {
    const routeGuard = this.$router.beforeEach((to, from, next) => {
      // add route guard validation here
      next(true)
    })

    this.$once('hook:destroyed', () => {
      routeGuard()
    })
  }
}
</script>
